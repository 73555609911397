<template>
  <v-pagination
    v-model="currentPage"
    :length="pageCount"
    circle
    class="py-2"
    @input="$emit('update:page', currentPage)"
  ></v-pagination>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    count: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  setup(props) {
    const currentPage = ref(props.page ?? 1)
    const pageCount = ref(props.count ?? 1)

    watch(
      () => props,
      () => {
        currentPage.value = props.page
        pageCount.value = props.count
      },
    )

    return { currentPage, pageCount }
  },
}
</script>
