<template>
  <v-icon size="13">
      {{ mdiArrowUpDown  }}
  </v-icon>
</template>

<script>
import { mdiArrowUpDown } from '@mdi/js'

export default {
  name: 'SortingArrows',
  setup() {
    return {
      mdiArrowUpDown,
    }
  },
}
</script>
